var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "名称", prop: "name" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入名称" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _vm.form.parentId !== 0 && _vm.form.parentId !== "0"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "父类名称", prop: "parentId" } },
                [_vm._v(" " + _vm._s(_vm.form.parentName) + " ")]
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "价格(元/KG)-站点", prop: "price" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入价格 单位:元" },
                model: {
                  value: _vm.form.price,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "price", $$v)
                  },
                  expression: "form.price",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "价格(元/KG)-上门", prop: "price2" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入价格 单位:元" },
                model: {
                  value: _vm.form.price2,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "price2", $$v)
                  },
                  expression: "form.price2",
                },
              }),
            ],
            1
          ),
          _vm.open
            ? _c(
                "a-form-model-item",
                { attrs: { label: "图标代码", prop: "icon" } },
                [
                  _c("many-file-upload", {
                    attrs: {
                      "init-file-list": _vm.form.initFileList,
                      billImageUrl: _vm.form.icon,
                      folder: "recyclable",
                      type: "image",
                      maxFileCount: 1,
                    },
                    on: {
                      onUploading: function ($event) {
                        _vm.uploading = $event
                      },
                      fileList: function ($event) {
                        _vm.form.icon =
                          $event && $event.length > 0
                            ? $event
                                .map(function (p) {
                                  return p.fileName
                                })
                                .join(",")
                            : ""
                      },
                    },
                    model: {
                      value: _vm.form.icon,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "icon", $$v)
                      },
                      expression: "form.icon",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.selfSettingPriceSwitch
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "self-title" }, [
                    _vm._v("价格区间（元/KG)-自主设置"),
                  ]),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "最高价", prop: "maxPrice" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入价格 单位:元" },
                        model: {
                          value: _vm.form.maxPrice,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "maxPrice", $$v)
                          },
                          expression: "form.maxPrice",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "最低价", prop: "minPrice" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入价格 单位:元" },
                        model: {
                          value: _vm.form.minPrice,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "minPrice", $$v)
                          },
                          expression: "form.minPrice",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "默认价", prop: "defaultPrice" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入价格 单位:元" },
                        model: {
                          value: _vm.form.defaultPrice,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "defaultPrice", $$v)
                          },
                          expression: "form.defaultPrice",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary", loading: _vm.saveLoading },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }