<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入名称" />
      </a-form-model-item>
      <a-form-model-item label="父类名称" v-if="form.parentId !== 0 && form.parentId !== '0'" prop="parentId">
        {{ form.parentName }}
      </a-form-model-item>
      <a-form-model-item label="价格(元/KG)-站点" prop="price">
        <a-input v-model="form.price" placeholder="请输入价格 单位:元" />
      </a-form-model-item>
      <a-form-model-item label="价格(元/KG)-上门" prop="price2">
        <a-input v-model="form.price2" placeholder="请输入价格 单位:元" />
      </a-form-model-item>
      <a-form-model-item label="图标代码" prop="icon" v-if="open">
        <many-file-upload
          :init-file-list="form.initFileList"
          :billImageUrl="form.icon"
          v-model="form.icon"
          folder="recyclable"
          type="image"
          :maxFileCount="1"
          @onUploading="uploading = $event"
          @fileList="form.icon = $event && $event.length > 0 ? $event.map(p => p.fileName).join(',') : ''"
        ></many-file-upload>
      </a-form-model-item>
      <div v-if="selfSettingPriceSwitch">
        <div class="self-title">价格区间（元/KG)-自主设置</div>
        <a-form-model-item label="最高价" prop="maxPrice">
          <a-input v-model="form.maxPrice" placeholder="请输入价格 单位:元" /> </a-form-model-item
        ><a-form-model-item label="最低价" prop="minPrice">
          <a-input v-model="form.minPrice" placeholder="请输入价格 单位:元" /> </a-form-model-item
        ><a-form-model-item label="默认价" prop="defaultPrice">
          <a-input v-model="form.defaultPrice" placeholder="请输入价格 单位:元" />
        </a-form-model-item>
      </div>
      <!-- <a-form-model-item label="是否删除" prop="status">
        <a-radio-group v-model="form.status" button-style="solid">
          <a-radio-button v-for="(item, index) in statusOptions" :key="index" :value="item.dictValue">{{
            item.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item> -->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="saveLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getRecyclingKind, addRecyclingKind, updateRecyclingKind } from '@/api/recyclable/recyclingKind'
import ManyFileUpload from '@/components/ManyFileUpload'
import { sysSettingList } from '@/api/recyclable/sysSetting'

export default {
  name: 'CreateForm',
  props: {
    statusOptions: {
      type: Array,
      default: () => []
    },
    parentList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    ManyFileUpload
  },
  data() {
    return {
      loading: false,
      saveLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        name: null,
        parentId: 0,
        price: null,
        price2: null,
        icon: null,
        tenantCode: null,
        status: null,
        maxPrice: 0,
        minPrice: 0,
        defaultPrice: 0
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      initFileList: [],
      rules: {
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
        parentId: [{ required: true, message: '父类ID不能为空', trigger: 'blur' }],
        price: [{ required: true, message: '价格-站点 单位:元不能为空', trigger: 'blur' }],
        price2: [{ required: true, message: '价格-上门 单位:元不能为空', trigger: 'blur' }],
        icon: [{ required: true, message: '图标代码不能为空', trigger: 'blur' }]
      },
      selfSettingPriceSwitch: false
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {
    selfSettingPriceSwitch(val) {
      console.log('')
      if (val) {
        !this.keepRules && (this.keepRules = JSON.parse(JSON.stringify(this.rules)))
        this.rules.maxPrice = [{ required: true, message: '最高价不能为空', trigger: 'blur' }]
        this.rules.minPrice = [{ required: true, message: '最低价不能为空', trigger: 'blur' }]
        this.rules.defaultPrice = [{ required: true, message: '默认价不能为空', trigger: 'blur' }]
      } else {
        this.keepRules && (this.rules = this.keepRules)
      }
    }
  },
  mounted() {
    this.sysSettingList()
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        name: null,
        parentId: 0,
        price: null,
        icon: null,
        tenantCode: null,
        status: null
      }
    },
    sysSettingList() {
      sysSettingList().then(res => {
        this.selfSettingPriceSwitch = !!res.data.selfSettingPriceSwitch
      })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getRecyclingKind(id).then(response => {
        this.form = response.data
        if (this.form.parentId !== 0 && !this.form.parentName) {
          for (const item of this.parentList) {
            if (item.id === this.form.parentId) {
              this.form.parentName = item.name
            }
          }
        }
        if (this.form.selfSettingPrice) {
          const selfSettingPrice = JSON.parse(this.form.selfSettingPrice)
          for (const key in selfSettingPrice) {
            this.form[key] = selfSettingPrice[key]
          }
        }
        for (const key in this.form) {
          if (typeof this.form[key] === 'number') {
            this.form[key] = this.form[key] + ''
          }
        }
        this.form.initFileList = { billImage: this.form.icon, billImageUrl: [this.form.iconUrl] }
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 行按钮新增操作 */
    handleRowAdd(row, ids) {
      this.reset()
      this.form.parentId = row.id
      this.form.parentName = row.name
      this.open = true
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.saveLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateRecyclingKind(this.form)
              .then(response => {
                this.saveLoading = false
                this.$message.success('修改成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .catch(() => {
                this.saveLoading = false
              })
          } else {
            addRecyclingKind(this.form)
              .then(response => {
                this.saveLoading = false
                this.$message.success('新增成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .catch(() => {
                this.saveLoading = false
              })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.self-title {
  font-size: 18px;
}
</style>
